import React from "react";
import {Helmet} from 'react-helmet';
import {useHistory} from "react-router-dom";
import {useIntl} from "react-intl";
import { AppRoute } from "../../const";
import { Route } from "../../modules/i18n";


export const PrizesOld: React.FC = () => {
    const {formatMessage} = useIntl();

    const history = useHistory();
    history.push(Route(AppRoute.Prizes));

    return (
        <>
            <Helmet>
                <title>{formatMessage({id: "during.prizes.title"})} | SantYaGo10K 2025</title>
            </Helmet>
        </>
    );
};

export default PrizesOld;
