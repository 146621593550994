export enum AppRoute {
    Home = 'routes.home',

    Before = 'routes.before',
    Join = 'routes.before.join',
    Dorsal = 'routes.before.dorsal',
    GetTo = 'routes.before.getto',
    Parking = 'routes.before.parking',
    Eating = 'routes.before.eating',
    Chip = 'routes.before.chip',
    Bag = 'routes.before.bag',

    During = 'routes.during',
    TrackOld = 'routes.during.track-old',
    Track = 'routes.during.track',
    TrackScholar = 'routes.during.track-scholar',
    Clothes = 'routes.during.clothes',
    Drinking = 'routes.during.drinking',
    Showers = 'routes.during.showers',
    PrizesOld = 'routes.during.prizes-old',
    Prizes = 'routes.during.prizes',
    PrizesScholar = 'routes.during.prizes-scholar',

    After = 'routes.after',
    Results = 'routes.after.results',
    Photos = 'routes.after.photos',
    Dinner = 'routes.after.dinner',
    Sleeping = 'routes.after.sleeping',
    Visiting = 'routes.after.visiting',

    Info = 'routes.info',
    PastYears = 'routes.info.pastyears',
    Regulation = 'routes.info.regulation',
    Terms = 'routes.info.terms',
    Press = 'routes.info.press',

    Volunteer = 'routes.volunteer',
    Contact = 'routes.contact',

    Admin = 'routes.admin',
    Calendar = 'routes.admin.calendar'
}

export const AppRouteTitles = new Map([
    [AppRoute.Home, 'home.title'],

    [AppRoute.Before, 'before.title'],
    [AppRoute.Join, 'before.join.title'],
    [AppRoute.Dorsal, 'before.dorsal.title'],
    [AppRoute.GetTo, 'before.getto.title'],
    [AppRoute.Parking, 'before.parking.title'],
    [AppRoute.Eating, 'before.eating.title'],
    [AppRoute.Chip, 'before.chip.title'],
    [AppRoute.Bag, 'before.bag.title'],

    [AppRoute.During, 'during.title'],
    [AppRoute.Track, 'during.track.title'],
    [AppRoute.TrackScholar, 'during.track-scholar.title'],
    [AppRoute.Clothes, 'during.clothes.title'],
    [AppRoute.Drinking, 'during.drinking.title'],
    [AppRoute.Showers, 'during.showers.title'],
    [AppRoute.Prizes, 'during.prizes.title'],
    [AppRoute.PrizesScholar, 'during.prizes-scholar.title'],

    [AppRoute.After, 'after.title'],
    [AppRoute.Results, 'after.results.title'],
    [AppRoute.Photos, 'after.photos.title'],
    [AppRoute.Dinner, 'after.dinner.title'],
    [AppRoute.Sleeping, 'after.sleeping.title'],
    [AppRoute.Visiting, 'after.visiting.title'],

    [AppRoute.Info, 'info.title'],
    [AppRoute.PastYears, 'info.pastyears.title'],
    [AppRoute.Regulation, 'info.regulation.title'],
    [AppRoute.Terms, 'info.terms.title'],
    [AppRoute.Press, 'info.press.title'],

    [AppRoute.Volunteer, 'volunteer.title'],
    [AppRoute.Contact, 'contact.title'],

    [AppRoute.Admin, 'admin.title'],
    [AppRoute.Calendar, 'admin.calendar.title'],
]);
